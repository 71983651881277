interface DropdownClasses {
  container?: Record<string, boolean>
  backdrop?: Record<string, boolean>
  dropdown?: Record<string, boolean>
  header?: Record<string, boolean>
  content?: Record<string, boolean>
  footer?: Record<string, boolean>
  trigger?: Record<string, boolean>
}

const defaultClasses: DropdownClasses = {
  container: {
    'z-50 flex flex-col items-end justify-end': true,
  },
  backdrop: {
    'fixed inset-0 z-10 bg-black/50 backdrop-blur-[2px] transition-opacity duration-300':
      true,
  },
  dropdown: {
    'z-40 overflow-hidden shadow-lg py-4 md:mt-1': true,
    'dark:text-light light:text-dark bg-neutral-light-2 dark:bg-neutral-dark-2':
      true,
    'rounded-t-lg md:rounded-b-lg': true,
    'duration-200 transition-opacity': true,
    'flex flex-col': true,
    'max-h-[90vh]': true,
  },
  header: {
    'flex justify-between gap-4 px-4 pb-4 empty:hidden': true,
  },
  content: {
    'scrollbar-themed overflow-y-auto flex-grow': true,
  },
  footer: {
    'flex justify-between gap-4 px-4 pt-4 empty:hidden': true,
  },
}

export default function useDropdown({
  classes = {},
  onClose,
  style,
}: {
  onClose: () => void
  classes?: {
    container?: string
    backdrop?: string
    dropdown?: string
    header?: string
    content?: string
    footer?: string
    trigger?: string
  }
  style: DropdownStyle
}) {
  const isOpen = ref(false)
  const { isWidgetMode, frame } = useWidgetMode()

  function close() {
    isOpen.value = false
    setTimeout(onClose, 200)
  }

  function open() {
    setTimeout(() => {
      isOpen.value = true
    }, 1)
  }

  const { OFF_CANVAS, ON_PAGE } = DROPDOWN_STYLE

  const cssClasses = computed(() => ({
    container: {
      ...defaultClasses.container,
      'fixed inset-0 sm:p-10': style === OFF_CANVAS && !isWidgetMode.value,
      'absolute left-0 right-0 top-0 overflow-hidden sm:px-10 pt-20 sm:pb-10':
        style === OFF_CANVAS && isWidgetMode.value,
      'md:absolute w-full': style === ON_PAGE,
      [classes.container || '']: true,
    },
    backdrop: {
      ...defaultClasses.backdrop,
      'pointer-events-none opacity-0': !isOpen.value,
      'pointer-events-auto opacity-100': isOpen.value,
      [classes.backdrop || '']: true,
    },
    dropdown: {
      ...defaultClasses.dropdown,
      'opacity-0': !isOpen.value,
      'opacity-100': isOpen.value,
      'transition-transform': style === OFF_CANVAS,
      'translate-y-full': style === OFF_CANVAS && !isOpen.value,
      'translate-y-0 w-full': style === OFF_CANVAS && isOpen.value,
      [classes.dropdown || '']: true,
    },
    header: {
      ...defaultClasses.header,
      [classes.header || '']: true,
    },
    content: {
      ...defaultClasses.content,
      [classes.content || '']: true,
    },
    footer: {
      ...defaultClasses.footer,
      [classes.footer || '']: true,
    },
  }))

  const cssStyles = computed(() => {
    if (!isWidgetMode.value || !frame.value) {
      return {
        container: '',
      }
    }

    const { scrollTop = 0, visibleHeight } = frame.value

    return {
      container: `top: ${Math.max(0, scrollTop)}px; height: ${visibleHeight}px;`,
    }
  })

  return {
    isOpen,
    open,
    close,
    cssClasses,
    cssStyles,
  }
}
